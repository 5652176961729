<template>
  <v-card :height="210">
    <v-card-title class="mb-0 pb-1">
      Customer Information
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!user && !isLoading"
            md="12"
          >
            <h3 class="font-weight-light">
              No customer is assigned to this meter
            </h3>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Name
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.name }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Email
              </h4>
              <h4
                v-if="user.email !== null"
                class="font-weight-medium"
              >
                {{ user.email }}
              </h4>
              <h4
                v-else
                class="font-weight-medium"
              >
                Not provided
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Phone Number
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.phone }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Account Number
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.account_number }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Registration date
              </h4>
              <h4 class="font-weight-medium">
                {{ user.created_at | formatDate }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Total credit
              </h4>
              <h4
                class="font-weight-medium"
              >
                Ksh {{ user.account_balance | formatAccountBalance }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Total debt
              </h4>
              <h4
                class="font-weight-medium"
              >
                Ksh {{ user.account_balance | formatTotalUserDebt(user.unaccounted_debt) }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Account status
              </h4>
              <h4
                class="font-weight-medium"
              >
                <v-switch
                  v-if="user && !isLoading"
                  v-model="userStatus"
                  :loading="statusLoading"
                  :disabled="statusLoading"
                  inset
                  color="primary"
                  class="mt-0 pt-0"
                  @change="toggleUserStatus"
                >
                  <template v-slot:label>
                    <span :class="userStatus ? 'primary--text' : 'error--text'">
                      {{ userStatus ? 'Enabled' : 'Disabled' }}
                    </span>
                  </template>
                </v-switch>
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusLoading: false,
    }
  },
  computed: {
    userStatus: {
      get() {
        return this.user ? !this.user.is_disabled : false
      },
      set(value) {
        // This is handled by the toggleUserStatus method
      },
    },
  },
  methods: {
    async toggleUserStatus() {
      if (!this.user) return

      this.statusLoading = true
      try {
        // Explicitly use the opposite of current is_disabled status
        const newDisabledStatus = !this.user.is_disabled

        const response = await axios.put(`/users/${this.user.id}/toggle-status`, {
          status: !newDisabledStatus, // Invert again to match backend expectation
        })

        this.user.is_disabled = response.data.user.is_disabled
        this.$emit('user-updated', response.data.user)
        this.$notification.success(response.data.message)
      } catch (error) {
        this.$notification.error(error.response?.data?.message || 'Failed to update user status')

        // Revert the switch if the request fails
        // Note: this line ensures UI reflects the original state
      } finally {
        this.statusLoading = false
      }
    },
  },
}
</script>

<style>

</style>
